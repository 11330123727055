* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/* App */
.root-container {
  display: grid;
  grid-template-rows: 60px 1fr;
  height: 100vh;
  width: 100%;
}
.root-container.full-container {
  grid-template-rows: 1fr;
}

.header {
  background-color: rgb(0, 0, 0);
  position: relative;
  z-index: 1;
  display: block;
}

/* Home */
.unity-container {
  max-height: 100%;
  position: relative;
}
.unity-container-full {
  height: 100vh;
}
.loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loadingBar {
  background: #8c8c8c;
  width: 400px;
  height: 20px;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingBarFill {
  background: #8e7b7b;
  height: 100%;
  transition: width 0.3s ease-in-out;
}
.game {
  width: 100%;
  height: 100%;
  position: absolute;
}
.unity-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 10px;
  padding-right: 10px;
}
.header.hidden {
  display: none;
}
